<template>
<div style="padding:0;margin-top: 20px;">
	<div class="sameWidth">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/companyActive' }">公司业务</el-breadcrumb-item>
			<el-breadcrumb-item>招投标专题研究</el-breadcrumb-item>
		</el-breadcrumb>
		<div style="margin: 0;text-align: center;">
			<h1 class="font2" style="margin-bottom: 50px;">招投标专题研究</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="width: 50%;display: inline-block;vertical-align: middle;">
				<img src="../../../assets/images/companyActive/公司业务—研究.jpg" style="width: 100%;"/>
			</div><div style="width: 50%;display: inline-block;vertical-align:top;">
				<p style="text-indent: 2em;padding-left: 20px;font-size: 16px;color: #595959;line-height: 30px;text-align: left;" >
					目前承接的招投标专题研究业务有智能辅助评标、投标人画像、围标串标等，通过专注某个专题的研究，为客户提供更深层次的专业解决方案，提升客户招投标管理能力与效率，降低招投标管理风险。
				</p>
			</div>
		</div>
		<div style="margin: 50px 0;text-align: center;">
			<h1 style="margin-bottom: 50px;" class="font2">研究领域</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="display: flex;text-align: center;">
				<div style="flex: 1;padding:5px;" v-for="(item,index) in servicesuperiority.slice(0,4)" :key="index">
					<div style="padding:4% 0;border:#E4E7ED 3px solid;border-radius: 5px;">
						<i class="iconfont" v-bind:class="item.icon" style="font-size:80px;color: #409EFF"></i>
						<h4>{{item.title}}</h4>
					</div>
				</div>
			</div>
			<div style="display: flex;text-align: center;">
				<div style="flex: 1;padding:5px;" v-for="(item,index) in servicesuperiority.slice(4,8)" :key="index">
					<div style="padding:4% 0;border:#E4E7ED 3px solid;border-radius: 5px;">
						<i class="iconfont" v-bind:class="item.icon" style="font-size:80px;color: #409EFF"></i>
						<h4>{{item.title}}</h4>
					</div>
				</div>
			</div>
		</div>
		<div style="margin: 100px 0;text-align: center;">
			<h1 style="margin-bottom: 50px;" class="font2">智能评标专题</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div id="liuCheng">
				<div class="iconDiv">
					<p class="iconfont  white" v-bind:class="project[0].icon"></p>
					<h4>{{project[0].title}}</h4>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont  white" v-bind:class="project[1].icon"></p>
					<h4>{{project[1].title}}</h4>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont  white" v-bind:class="project[2].icon"></p>
					<h4>{{project[2].title}}</h4>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont  white" v-bind:class="project[3].icon"></p>
					<h4>{{project[3].title}}</h4>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont  white" v-bind:class="project[4].icon"></p>
					<h4>{{project[4].title}}</h4>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont  white" v-bind:class="project[5].icon"></p>
					<h4>{{project[5].title}}</h4>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont  white" v-bind:class="project[6].icon"></p>
					<h4>{{project[6].title}}</h4>
				</div>
			</div>
		</div>
		<div style="margin: 50px 0;text-align: center;">
			<h1 style="margin-bottom: 50px;" class="font2">投标人画像专题</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="display: flex;">
				<div style="flex: 1;"   v-for="(item,index) in serviceprocessing" :key="index">
					<div style="padding:5px;">
						<img :src="item.src" style="width: 100%;"/>
					</div>
					
				</div>
			</div>
		</div>
	</div>
		
</div>
</template>
<style scoped>
	.font2{
		background-image: -webkit-gradient( linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff) );
		color: transparent;
		-webkit-background-clip: text;
	}
	.borderBottom{
		margin-top: -40px;
	}
	.borderBottom span{
		border-bottom:#FF8700 3px solid ;
		display: inline-block;
		width: 50px;
	}
	#liuCheng{
		display: flex;background-color:#409EFF;color: #fff;padding: 20px;
	}
	#liuCheng .iconDiv{
		flex-grow: 2;width: 0;padding-top: 20px;
	}
	#liuCheng .iconDiv2{
		flex-grow: 1;width: 0;padding-top: 10px;
	}
	#liuCheng .iconDiv2 .icon-jiantou{
		font-size:40px;
	}
	#liuCheng .iconDiv .white{
		display:inline-block;
		width: 100px;
		height: 100px;
		line-height: 100px;
		border-radius: 50%;
		font-size:50px;
		background-color: #fff;
		box-sizing: border-box;
		margin: 0;
		color:#409EFF;
	}
</style>
<script>
export default {
  data() {
    return {
		screenWidth:1300,
      headersrc: require("../../../assets/image/公司前台.jpg"),
		project:[
			{
			id: 1,
			icon: 'icon-109tiaokuanpeizhi',
			title: "评分条款标准化",
			},
			{
			id: 2,
			icon: 'icon-pingfen',
			title: "评分标准量化",
			},
			{
			id: 3,
			icon: 'icon-jisuanguize',
			title: "制定评分计算规则",
			},
			{
			id: 4,
			icon: 'icon-zidongshengcheng',
			title: "自动生成结构化招标文件",
			},{
			id: 5,
			icon: 'icon-gongnengmokuaihua',
			title: "投标文件结构化响应",
			},
			{
			id: 6,
			icon: 'icon-monikaoshi',
			title: "系统自动评分",
			},
			{
			id: 7,
			icon: 'icon-zhuanjiashenhe',
			title: "专家审核确定评分结果",
			}
		],
      serviceprocessing: [
		{
			src: require("../../../assets/images/tenderResearch/1.jpg"),
		},
		{
			src: require("../../../assets/images/tenderResearch/2.jpg"),
		},
		{
			src: require("../../../assets/images/tenderResearch/3.jpg"),
		},
      ],
      servicesuperiority: [
        {
          id: 1,
          icon: 'icon-zhaotoubiaoguanli',
          title: "招投标管理",
        },
        {
          id: 2,
		icon: 'icon-pingfen',
          title: "智能评标",
        },
        {
          id: 3,
          icon: 'icon-guanliangongsiweibiaochuanbiao',
          title: "围标串标",
        },
        {
          id: 4,
          icon: 'icon-tuisong',
          title: "标讯智能推送",
        },{
          id: 5,
          icon: 'icon-tisheng',
          title: "招投标技能提升",
        },
        {
          id: 6,
          icon: 'icon-toubiaobaojiayingda',
          title: "投标报价模拟仿真",
        },
        {
          id: 7,
          icon: 'icon-renwuhuaxiangyijiicom',
          title: "投标人画像",
        },
        {
          id: 8,
          icon: 'icon-gongnengmokuaihua',
          title: "更多",
        },
      ],
    };
  },
	mounted:function(){
		this.bus.$emit('headerImg',this.headerImg);
		this.screenWidth=document.documentElement.clientWidth;
		window.addEventListener('resize',()=>{
			this.screenWidth=document.documentElement.clientWidth;
		})
	}
};
</script>
